import React from "react"

export default function TestPage() {
  return <div className='bg-page sm:bg-primary md:bg-secondary lg:bg-secondary2 xl:bg-complementary p-10 text-center w-full flex flex-col'>
      <div className='bg-page font-bold sm:font-normal'>all</div>
      <div className='bg-primary sm:font-bold sm:text-page md:font-normal md:text-ink'>sm:small</div>
      <div className='bg-secondary md:font-bold md:text-page lg:font-normal lg:text-ink'>md:medium</div>
      <div className='bg-secondary2 lg:font-bold lg:text-page xl:font-normal xl:text-ink'>lg:large</div>
      <div className='bg-complementary xl:font-bold xl:text-page'>xl:extra-large</div>
    </div>
}
